import * as React from "react"
import axios from "axios"
// import { Link } from "gatsby"

import { toast } from "react-toastify"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => {
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [ok, setOk] = React.useState<boolean>(null)
  const [msg, setMsg] = React.useState<string>(null)

  const handleServerResponse = (
    wasSubmitted: boolean,
    message: string,
    form: HTMLFormElement
  ) => {
    const toastOptions = {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    }
    setSubmitting(false)
    setOk(wasSubmitted)
    setMsg(message)
    if (ok) {
      toast.success(msg, toastOptions)
      form.reset()
    } else {
      toast.error(msg, toastOptions)
    }
  }
  const handleOnSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault()
      const form = e.currentTarget as HTMLFormElement
      setSubmitting(true)

      axios({
        data: new FormData(form),
        method: "post",
        url: "https://getform.io/f/7bb091aa-d7cf-4fd3-8e8c-ddf9e5a6260b",
      })
        .then(() =>
          handleServerResponse(
            true,
            "Thank you. Your message has been submitted successfully.",
            form
          )
        )
        .catch(err =>
          handleServerResponse(false, err.response.data.error, form)
        )
    },
    []
  )

  return (
    <Layout>
      <SEO title="Contact Us" />
      <article className="measure-wide center sans-serif">
        <h1 className="tc">Contact Us</h1>
        <section>
          <h2 className="tc">Contact Form</h2>
          <p>Fill out this form to email Gabrielle</p>
          <form
            onSubmit={handleOnSubmit}
            method="post"
            action="https://getform.io/f/7bb091aa-d7cf-4fd3-8e8c-ddf9e5a6260b"
          >
            <div className="mt3">
              <label className="db fw6 lh-copy f6" htmlFor="name">
                Name
              </label>
              <input
                className="b pa2 br2 input-reset ba bg-transparent w-100"
                type="text"
                name="name"
                id="name"
              />
            </div>
            <div className="mv3">
              <label className="db fw6 lh-copy f6" htmlFor="email-address">
                Email Address
              </label>
              <input
                className="b pa2 br2 input-reset ba bg-transparent w-100"
                type="email"
                name="email-address"
                id="email-address"
              />
            </div>
            <div className="mv3">
              <label className="db fw6 lh-copy f6" htmlFor="comment">
                Comment
              </label>
              <textarea
                className="b pa2 br2 input-reset ba bg-transparent w-100"
                name="comment"
                id="comment"
              />
            </div>
            <button
              disabled={submitting}
              type="submit"
              className="b ph3 pv2 br2 input-reset ba b--black bg-transparent grow pointer f6 dib"
            >
              Send
            </button>
          </form>
        </section>
        <section className="w-80-ns w-100">
          <h2 className="tc">Other Ways to Contact</h2>
          <ul className="list lh-copy measure-narrow center">
            <li className="mb1">
              Reach out to Gabrielle on{" "}
              <a
                className="link hover-light-blue"
                href="//facebook.com/gabriel.moncrease"
              >
                Facebook
              </a>
            </li>
            <li className="mv1">
              <a
                className="link hover-light-blue"
                href="mailto:webmaster@gabriellemoncrease.com"
              >
                Email the webmaster
              </a>
              .
            </li>
            <li className="mv1">
              Gabrielle&apos;s mailing address:
              <address>
                Gabrielle Moncrease <br />
                1350 East Flamingo Road <br />
                #598 <br />
                Las Vegas, NV 89119
              </address>
            </li>
          </ul>
        </section>
      </article>
    </Layout>
  )
}

export default ContactPage
